import { gql } from '@apollo/client';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import MuiPaperBackButton from '../../mui/MuiPaper/MuiPaperBackButton';
import MuiStepper from '../../mui/MuiStepper/MuiStepper';
import { UserType } from '../../types/generated';
import { FlattenedUser } from '../../types/user';
import { PageHead, PageHeadBaseProps } from '../helpers';
import { showError, showSuccess } from '../Toast';
import styles from './InquiriesConfiguration.module.scss';
import { InquiriesConfigurationForm } from './InquiriesConfigurationForm';
import {
	inquiriesConfigurationFieldsInquiryFormFragment,
	useInquiriesConfigurationFields,
} from './InquiriesConfigurationForm/useInquiriesConfigurationFields';
import { InquiriesPublishForm } from './InquiriesPublishForm';
import { InquiriesStylingForm } from './InquiriesStylingForm';
import { useInquiriesConfiguration_GetInquiryFormQuery } from './__generated__';

const inquiriesConfigurationInquiryFormFragment = gql`
	fragment inquiriesConfigurationInquiryForm on InquiryForm {
		...inquiriesConfigurationFieldsInquiryForm
		...inquiriesStylingFormInquiryForm
	}
	${ inquiriesConfigurationFieldsInquiryFormFragment }
	${ InquiriesStylingForm.fragments.inquiriesStylingFormInquiryFormFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInquiryFormQuery = gql`
	query InquiriesConfiguration_GetInquiryForm(
		$where: OrganizationWhereUniqueInput!
	) {
		getInquiryForm(where: $where) {
			...inquiriesConfigurationInquiryForm
		}
	}
	${ inquiriesConfigurationInquiryFormFragment }
`;

enum InquiriesConfigurationSteps {
	Setup = 'setup',
	Style = 'style',
	Publish = 'publish',
}

type StepLabelsCastType = Record<
keyof typeof InquiriesConfigurationSteps,
string
>;

const stepLabels: Record<InquiriesConfigurationSteps, string> = {
	[ InquiriesConfigurationSteps.Setup ]: 'Setup',
	[ InquiriesConfigurationSteps.Style ]: 'Style',
	[ InquiriesConfigurationSteps.Publish ]: 'Publish',
};

export const queryValueIsInquiriesConfigurationStep = (
	potentialStep: string | string[] | undefined | InquiriesConfigurationSteps
): potentialStep is InquiriesConfigurationSteps =>
	typeof potentialStep === 'string' &&
	Object.values<string>( InquiriesConfigurationSteps ).includes( potentialStep );

export const Title: Record<InquiriesConfigurationSteps, string> = {
	[ InquiriesConfigurationSteps.Setup ]: 'Inquiries Form Setup',
	[ InquiriesConfigurationSteps.Style ]: 'Inquiries Form Styling',
	[ InquiriesConfigurationSteps.Publish ]: 'Inquiries Form Publish Markup',
};

export type InquiriesConfigurationStepsKeys = `${ InquiriesConfigurationSteps }`;

const getNextStep = ( step: InquiriesConfigurationStepsKeys ) => {
	switch ( step ) {
		case InquiriesConfigurationSteps.Setup:
			return InquiriesConfigurationSteps.Style;
		case InquiriesConfigurationSteps.Style:
			return InquiriesConfigurationSteps.Publish;
	}
};

const getPreviousStep = ( step: InquiriesConfigurationStepsKeys ) => {
	switch ( step ) {
		case InquiriesConfigurationSteps.Style:
			return InquiriesConfigurationSteps.Setup;
		case InquiriesConfigurationSteps.Publish:
			return InquiriesConfigurationSteps.Style;
	}
};

enum Directions {
	Next = 'next',
	Back = 'back',
}

const getPath = (
	direction: Directions,
	currentStep: InquiriesConfigurationStepsKeys
) =>
	direction === Directions.Back
		? `/admin/inquiries/${ getPreviousStep( currentStep ) }`
		: `/admin/inquiries/${ getNextStep( currentStep ) }`;

export type InquiriesConfigurationProps = PageHeadBaseProps & {
	goggleFontsApiKey: string;
	step: InquiriesConfigurationSteps;
};

export const InquiriesConfiguration: React.FC<InquiriesConfigurationProps> = ( {
	goggleFontsApiKey,
	step,
	hostnameAndProtocol,
} ) => {
	const user: FlattenedUser = useSelector( ( state: any ) => state.user );
	const router = useRouter();
	const { isMobile } = useDeviceDetection();
	// cook down some values we'll use with the stepper
	const activeStep = Object.values(
		InquiriesConfigurationSteps as Record<string, string>
	).indexOf( step );

	const backButtonHref = '/admin?tab=settings';

	// So we can make the buttons spinners when one is clicked
	const [ loading, setLoading ] = useState( false );
	useEffect( () => {
		setLoading( false );
	}, [ step ] );

	const organizationId =
		user?.isLoggedIn && user.userType === UserType.OrgUser
			? user.organization.id
			: undefined;

	const {
		data: inquiryFormData,
		error,
		loading: queryLoading,
	} = useInquiriesConfiguration_GetInquiryFormQuery( {
		variables: {
			where: { id: organizationId || '' },
		},
		skip: !organizationId,
	} );

	const inquiryForm = inquiryFormData?.getInquiryForm;

	const delayLoadingRef = useRef<ReturnType<typeof setTimeout>>();
	useEffect( () => {
		if ( delayLoadingRef.current ) {
			clearTimeout( delayLoadingRef.current );
			delayLoadingRef.current = undefined;
		}
		if ( queryLoading ) {
			setLoading( true );
		} else {
			delayLoadingRef.current = setTimeout( () => {
				setLoading( false );
				delayLoadingRef.current = undefined;
			}, 1000 );
		}
	}, [ queryLoading ] );

	useEffect( () => {
		if ( error ) {
			showError( error );
		}
	}, [ error ] );

	const {
		onNext,
		loading: hookMutationsLoading,
		...hookProps
	} = useInquiriesConfigurationFields( inquiryForm );

	const handleOnNext = useCallback( async () => {
		setLoading( true );
		if ( step === InquiriesConfigurationSteps.Publish ) {
			showSuccess( 'Form saved' );
			await router.push( backButtonHref );
			setLoading( false );
		} else if ( step === InquiriesConfigurationSteps.Setup ) {
			if ( await onNext() ) {
				await router.push( getPath( Directions.Next, step ) );
			} else {
				setLoading( false );
			}
		} else {
			await router.push( getPath( Directions.Next, step ) );
		}
	}, [
		step,
		router,
		onNext
	] );

	const handleOnBack = useCallback( async () => {
		setLoading( true );
		router.push( getPath( Directions.Back, step ) );
	}, [ router, step ] );

	if ( !organizationId ) {
		return null;
	}

	return (
		<>
			<PageHead
				hostnameAndProtocol={ hostnameAndProtocol }
				title={ Title[ step ] }
				path={ `/admin/inquiries/${ step }` }
			/>
			<main className={ styles.main }>
				<MuiPaperBackButton href={ backButtonHref } text='Admin' />
				{ isMobile ? (
					<Typography className={ styles.desktopOnly }>
						Your inquiry form can only be configured on desktop.
					</Typography>
				) : (
					<MuiStepper
						className={ styles.stepper }
						steps={ InquiriesConfigurationSteps }
						labels={ stepLabels as unknown as StepLabelsCastType }
						activeStep={ activeStep }
						onNext={ handleOnNext }
						onBack={ handleOnBack }
						loading={ loading }
					>
						{ step === InquiriesConfigurationSteps.Setup ? (
							<InquiriesConfigurationForm
								loading={ loading || hookMutationsLoading }
								{ ...hookProps }
							/>
						) : null }
						{ step === InquiriesConfigurationSteps.Style ? (
							<InquiriesStylingForm
								googleFontsApiKey={ goggleFontsApiKey }
								loading={ loading }
								inquiryForm={ inquiryForm }
							/>
						) : null }
						{ step === InquiriesConfigurationSteps.Publish ? (
							<InquiriesPublishForm organizationId={ organizationId } />
						) : null }
					</MuiStepper>
				) }
			</main>
		</>
	);
};
