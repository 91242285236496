import { InquiryFormFieldType } from './../../../types/generated';
import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import {
	Columns,
	commonFieldDataIsFirstNameTwo,
	MutableProps,
	NotFirstNameField,
	SecondNameField,
} from './types';
import rpcShared, { RPCShared } from '@rockpapercoin/rpc-shared';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import { gql } from '@apollo/client';
import {
	InquiriesConfigurationFieldsInquiryFormFragment,
	useInquiriesConfigurationFields_CreateInquiryFormMutation,
	useInquiriesConfigurationFields_UpdateInquiryFormMutation,
} from './__generated__/useInquiriesConfigurationFields';
import { showError } from '../../Toast';
import { InquiryFormDataInput } from '../../../types/generated';
import { ArrayElement, notEmpty } from '../../../lib/helpers/apollo';

type Field = RPCShared.Inquiries.Field;
type FieldType = Field['type'];

const getDefaultLabelById = ( type: FieldType, description: string ) => {
	if ( type === InquiryFormFieldType.FirstNameTwo ) {
		return 'Second first name';
	} else if ( type === InquiryFormFieldType.LastNameTwo ) {
		return 'Second last name';
	} else if ( type === InquiryFormFieldType.GuestCount ) {
		return 'How many people will be at your event?';
	} else if ( type === InquiryFormFieldType.Budget ) {
		return 'What is your total estimated budget?';
	} else if ( type === InquiryFormFieldType.Services ) {
		return 'What services are you interested in?';
	} else if ( type === InquiryFormFieldType.CustomText ) {
		return 'Additional details';
	} else {
		return description;
	}
};

const getColumnLabelById = ( type: FieldType ) => {
	if (
		[
			InquiryFormFieldType.FirstNameOne,
			InquiryFormFieldType.FirstNameTwo,
			InquiryFormFieldType.Email,
		].includes( type )
	) {
		return Columns.Left;
	} else if (
		[
			InquiryFormFieldType.LastNameOne,
			InquiryFormFieldType.LastNameTwo,
			InquiryFormFieldType.Phone,
		].includes( type )
	) {
		return Columns.Right;
	} else {
		return Columns.Both;
	}
};

type APIFieldData = Omit<
ArrayElement<InquiriesConfigurationFieldsInquiryFormFragment['fields']>,
'__typename'
>;

const supplementWithDefaultData = (
	items: ( Partial<APIFieldData> & { type: InquiryFormFieldType } )[],
	includedList?: APIFieldData[] | null
): ( SecondNameField | NotFirstNameField )[] =>
	items.map( ( item, order ) => {
		const defaults = rpcShared.inquiries.fields.find(
			( field ) => field.type === item.type
		);
		if ( !defaults ) {
			throw new Error( 'Unexpected field type found' );
		}
		const included =
			!!defaults.forceRequired ||
			!!includedList?.find( ( field ) => field.type === item.type );
		return {
			...defaults,
			...item,
			order: typeof item.order === 'number' ? item.order : order,
			label: item.label || getDefaultLabelById( item.type, defaults.description ),
			required: item.required || !!defaults.forceRequired,
			column: getColumnLabelById( item.type ),
			included,
			validation: undefined,
		};
	} );

export const inquiriesConfigurationFieldsInquiryFormFragment = gql`
	fragment inquiriesConfigurationFieldsInquiryForm on InquiryForm {
		instructions
		fields {
			order
			type
			label
			required
			services
			multiple
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createInquiryFormMutation = gql`
	mutation InquiriesConfigurationFields_CreateInquiryForm(
		$data: InquiryFormDataInput!
	) {
		createInquiryForm(data: $data) {
			...inquiriesConfigurationFieldsInquiryForm
		}
	}
	${ inquiriesConfigurationFieldsInquiryFormFragment }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateInquiryFormMutation = gql`
	mutation InquiriesConfigurationFields_UpdateInquiryForm(
		$data: InquiryFormDataInput!
	) {
		updateInquiryForm(data: $data) {
			...inquiriesConfigurationFieldsInquiryForm
		}
	}
	${ inquiriesConfigurationFieldsInquiryFormFragment }
`;

export type HookFieldData = ArrayElement<
ReturnType<typeof supplementWithDefaultData>
>;

export const useInquiriesConfigurationFields = (
	inquiryForm?: InquiriesConfigurationFieldsInquiryFormFragment | null
) => {
	const initialItems = useMemo(
		() =>
			supplementWithDefaultData( rpcShared.inquiries.fields ).filter(
				( field ) => field.included
			),
		[]
	);
	const [ usedItems, setUsedItems ] = useState<HookFieldData[]>( [] );
	const [ availableItems, setAvailableItems ] = useState<HookFieldData[]>( [] );
	const [ instructions, setInstructions ] = useState<string | undefined>();
	const [ instructionsValidation, setInstructionsValidation ] = useState<
	string | undefined
	>();
	const [ createInquiryForm, { loading: createInquiryFormLoading } ] =
		useInquiriesConfigurationFields_CreateInquiryFormMutation( {
			refetchQueries: [ 'InquiriesConfiguration_GetInquiryForm' ],
		} );
	const [ updateInquiryForm, { loading: updateInquiryFormLoading } ] =
		useInquiriesConfigurationFields_UpdateInquiryFormMutation( {
			refetchQueries: [ 'InquiriesConfiguration_GetInquiryForm' ],
		} );
	const loading = createInquiryFormLoading || updateInquiryFormLoading;

	useEffect( () => {
		const allItems = supplementWithDefaultData(
			rpcShared.inquiries.fields,
			inquiryForm?.fields
		);
		const lastItemTwo = allItems.find(
			( item ) => item.type === InquiryFormFieldType.LastNameTwo
		);
		const firstNameTwo = allItems.find(
			( item ) => item.type === InquiryFormFieldType.FirstNameTwo
		);
		if ( commonFieldDataIsFirstNameTwo( firstNameTwo ) && lastItemTwo ) {
			firstNameTwo.description = 'Additional name';
			firstNameTwo.lastNameTwo = lastItemTwo;
		}
		// Omit lastNameTwo from the list. included it only as a prop on firstNameTwo
		setAvailableItems(
			allItems.filter( ( { type } ) => type !== InquiryFormFieldType.LastNameTwo )
		);
	}, [ inquiryForm?.fields ] );

	useEffect( () => {
		setInstructions( inquiryForm?.instructions || '<p>Contact us</p>' );
		if ( inquiryForm?.fields ) {
			const items = supplementWithDefaultData(
				inquiryForm.fields,
				inquiryForm.fields
			);
			const lastItemTwo = items.find(
				( item ) => item.type === InquiryFormFieldType.LastNameTwo
			);
			const firstNameTwo = items.find(
				( item ) => item.type === InquiryFormFieldType.FirstNameTwo
			);
			if ( commonFieldDataIsFirstNameTwo( firstNameTwo ) && lastItemTwo ) {
				firstNameTwo.lastNameTwo = lastItemTwo;
			}
			setUsedItems(
				items
					.filter( ( { type } ) => type !== InquiryFormFieldType.LastNameTwo )
					.sort( ( a, b ) => a.order - b.order )
			);
		} else {
			setUsedItems( initialItems );
		}
	}, [ initialItems, inquiryForm ] );

	const validateOnBlurRef = useRef( false );

	const validate = useCallback(
		( force?: boolean ) => {
			if ( force === true ) {
				validateOnBlurRef.current = force;
			}
			const validateOnBlur = validateOnBlurRef.current || force === true;
			let isValid = true;
			if ( instructions === '<p></p>' ) {
				isValid = false;
				if ( validateOnBlur ) {
					setInstructionsValidation( 'Instructions are required' );
				}
			} else {
				if ( validateOnBlur ) {
					setInstructionsValidation( undefined );
				}
			}
			if ( validateOnBlur ) {
				const newState = [ ...usedItems ];
				newState.forEach( ( field ) => {
					if (
						field.type === InquiryFormFieldType.Services &&
						( !Array.isArray( field.services ) ||
							( field.services as string[] ).filter( notEmpty ).length === 0 )
					) {
						/* first, check that, if services is selected, that at least one service name is provided
						This validation is only run client-side, otherwise we could never add the services field
						without already knowing what services we'd want to add */
						isValid = false;
						field.validation =
							rpcShared.strings.errorMessages.inquiryFormServicesCannotBeEmpty;
					} else if (
						field.type === InquiryFormFieldType.Services &&
						!field.label
					) {
						/* first, check that, if services is selected, that at least one service name is provided
						This validation is only run client-side, otherwise we could never add the services field
						without already knowing what services we'd want to add */
						isValid = false;
						field.validation =
							rpcShared.strings.errorMessages.inquiryFormRequiresLabels;
					} else if ( !field.label ) {
						// Then check that all fields have labels
						isValid = false;
						field.validation =
							rpcShared.strings.errorMessages.inquiryFormRequiresLabels;
					} else if ( field.validation ) {
						// otherwise, if validation was previously set and no longer applies, unset it
						field.validation = undefined;
					}
					if ( commonFieldDataIsFirstNameTwo( field ) ) {
						if ( !field.lastNameTwo.label ) {
							// Then check that event the lastNameTwo field has a label
							isValid = false;
							field.lastNameTwo.validation =
								rpcShared.strings.errorMessages.inquiryFormRequiresLabels;
						} else {
							// otherwise, if validation was previously set and no longer applies, unset it
							field.lastNameTwo.validation = undefined;
						}
					}
				} );
				setUsedItems( newState );
			}
			return isValid;
		},
		[ instructions, usedItems ]
	);

	const mutate = useCallback( async () => {
		if ( validate( true ) ) {
			const firstNameTwo = usedItems.find( ( item ) =>
				commonFieldDataIsFirstNameTwo( item )
			);
			const adulteratedItems: Pick<
			ArrayElement<typeof usedItems>,
			'type' | 'order' | 'label' | 'required' | 'services' | 'multiple'
			>[] =
				firstNameTwo && commonFieldDataIsFirstNameTwo( firstNameTwo )
					? [ ...usedItems, { ...firstNameTwo.lastNameTwo, order: firstNameTwo.order }, ]
					: usedItems;
			const fields: InquiryFormDataInput['fields'] = adulteratedItems
				.filter( ( field ) => typeof field.order === 'number' )
				.sort( ( a, b ) => a.order - b.order )
				.map( ( field, index ) => ( {
					type: field.type,
					order: index,
					label: field.label,
					services: field.services,
					multiple: field.multiple,
					required: field.required,
				} ) );
			const response = await ( inquiryForm
				? updateInquiryForm
				: createInquiryForm )( {
				variables: {
					data: {
						instructions,
						fields,
					},
				},
			} );
			if ( response.errors ) {
				response.errors.forEach( ( error ) => showError( error ) );
				return false;
			} else {
				validateOnBlurRef.current = false;
				return true;
			}
		} else {
			return false;
		}
	}, [
		createInquiryForm,
		inquiryForm,
		instructions,
		updateInquiryForm,
		usedItems,
		validate,
	] );

	const setItem = useCallback(
		<
			T extends keyof Pick<
			HookFieldData,
			'label' | 'required' | 'services' | 'multiple'
			>,
			K extends HookFieldData[T]
		>(
			type: FieldType,
			field: T,
			value: K
		) =>
			setUsedItems( ( prevState ) => {
				const fields = [ ...prevState ];
				const firstNameTwo = fields.find( commonFieldDataIsFirstNameTwo );
				const item =
					type === InquiryFormFieldType.LastNameTwo && firstNameTwo
						? ( firstNameTwo.lastNameTwo as HookFieldData )
						: fields.find( ( item ) => item.type === type );
				if ( item ) {
					item[ field ] = value;
					return fields;
				}
				return prevState;
			} ),
		[]
	);

	const handleToggle = useCallback( ( type: FieldType, value: boolean ) => {
		setAvailableItems( ( prevState ) => {
			const newState = [ ...prevState ];
			const item = newState.find( ( item ) => item.type === type );
			if ( item ) {
				item.included = value;
				if ( value ) {
					const maxOrder = prevState.reduce(
						( acc, item ) => ( item.order > acc ? item.order : acc ),
						0
					);
					// put the new item at the bottom
					item.order = maxOrder + 1;
					setUsedItems( ( prevState ) =>
						[ ...prevState, item ]
							.sort( ( a, b ) => a.order - b.order )
							.map( ( item, order ) => ( { ...item, order } ) )
					);
				} else {
					setUsedItems( ( prevState ) =>
						prevState.filter( ( item ) => item.type !== type )
					);
				}
				return newState;
			} else {
				return prevState;
			}
		} );
	}, [] );

	const stateMutations = useCallback(
		( type: FieldType ): MutableProps => ( {
			setRequired: ( value ) => setItem( type, 'required', value ),
			setLabel: ( value ) => setItem( type, 'label', value ),
			setServices: ( value ) => setItem( type, 'services', value ),
			setMultiple: ( value ) => setItem( type, 'multiple', value ),
		} ),
		[ setItem ]
	);

	const reorder = useCallback(
		<T extends HookFieldData>(
			items: T[],
			sourceIndex: number,
			destinationIndex: number
		): T[] => {
			// using Array.from here to cover instances when the "list" is coming out of Apollo as readonly
			const mutableItems = Array.from( items );
			const [ removed ] = mutableItems.splice( sourceIndex, 1 );
			mutableItems.splice( destinationIndex, 0, removed );
			return mutableItems.map( ( item, order ) => ( { ...item, order } ) );
		},
		[]
	);

	const onDragEnd: OnDragEndResponder = useCallback(
		( result ) => {
			const { reason, destination, source } = result;
			if ( reason === 'DROP' && destination ) {
				if ( source.droppableId === destination.droppableId ) {
					// we're reordering within the same list
					setUsedItems( ( prevState ) =>
						reorder( prevState, source.index, destination.index )
					);
				} else {
					/* Being in here means we're dragging from one list to another
					but that's not supported here, so we do nothing */
				}
			}
		},
		[ reorder ]
	);

	const onNext = useCallback( () => mutate(), [ mutate ] );

	return {
		availableItems,
		usedItems: usedItems.map( ( item ) => ( {
			...item,
			...stateMutations( item.type ),
			...( commonFieldDataIsFirstNameTwo( item )
				? {
					lastNameTwo: {
						...item.lastNameTwo,
						...stateMutations( InquiryFormFieldType.LastNameTwo ),
						onBlur: validate,
					},
				  }
				: {} ),
			onBlur: validate,
		} ) ),
		instructions,
		setInstructions,
		instructionsValidation,
		handleToggle,
		onDragEnd,
		loading,
		onNext,
		validate,
	};
};
