import { Box, Divider, List, Typography } from '@mui/material';
import React from 'react';
import {
	InquiriesConfigurationFormStandardField,
	itemIsStandardField,
	InquiriesFormSimpleItem,
} from './InquiriesConfigurationFormFields';
import styles from './InquiriesConfigurationForm.module.scss';
import classNames from 'classnames';
import { InquiriesConfigurationFormServicesField } from './InquiriesConfigurationFormFields/InquiriesConfigurationFormServicesField';
import MuiTipTap from '../../../mui/MuiTipTap';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { InquiryFormFieldType } from '../../../types/generated';
import MuiSpinner from '../../../mui/MuiSpinner';
import { useInquiriesConfigurationFields } from './useInquiriesConfigurationFields';

export type InquiriesConfigurationFormProps = {
	loading: boolean;
} & Omit<ReturnType<typeof useInquiriesConfigurationFields>, 'onNext'>;

export const InquiriesConfigurationForm: React.FC<
InquiriesConfigurationFormProps
> = ( {
	loading,
	handleToggle,
	availableItems,
	instructions,
	setInstructions,
	instructionsValidation,
	usedItems,
	onDragEnd,
	validate,
} ) => (
	<Box className={ styles.container }>
		{ loading ? (
			<Box className={ styles.loading }>
				<MuiSpinner />
			</Box>
		) : null }
		<Box className={ styles.column }>
			<Typography variant='h6'>Available fields</Typography>
			<List className={ styles.list } disablePadding data-cy='available-fields'>
				<InquiriesFormSimpleItem
					type={ InquiryFormFieldType.CustomText }
					description='Instructions'
					forceRequired
					included
					onToggle={ handleToggle }
				/>
				{ availableItems
					.filter( ( item ) => item.type !== InquiryFormFieldType.LastNameTwo )
					.map( ( item ) => (
						<InquiriesFormSimpleItem
							key={ item.type }
							{ ...item }
							onToggle={ handleToggle }
						/>
					) ) }
			</List>
		</Box>
		<Divider orientation='vertical' />
		<Box className={ styles.column }>
			<Typography variant='h6'>Layout</Typography>
			<MuiTipTap
				fullWidth
				label='Instructions'
				value={ instructions === '<p>Contact us</p>' ? null : instructions }
				onChange={ setInstructions }
				countCharacters
				maxLength={ 1000 }
				placeholder='Please contact us directly or fill out the form below, and one of our team members will be in touch shortly!'
				errorText={ instructionsValidation }
				onBlur={ validate }
			/>
			<DragDropContext onDragEnd={ onDragEnd }>
				<Droppable droppableId='configuration'>
					{ ( provided ) => (
						<List
							disablePadding
							className={ classNames( styles.list, styles.large ) }
							{ ...provided.droppableProps }
							ref={ provided.innerRef }
							data-cy='layout'
						>
							{ usedItems
								.sort( ( a, b ) => a.order - b.order )
								.map( ( item, index ) =>
									itemIsStandardField( item ) ? (
										<InquiriesConfigurationFormStandardField
											key={ item.type }
											{ ...item }
											index={ index }
										/>
									) : (
										<InquiriesConfigurationFormServicesField
											key={ item.type }
											{ ...item }
											index={ index }
										/>
									)
								) }
							{ provided.placeholder }
						</List>
					) }
				</Droppable>
			</DragDropContext>
		</Box>
	</Box>
);
