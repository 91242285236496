import {
	IconButton,
	ListItemButton,
	ListItemProps,
	PaperProps,
} from '@mui/material';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Icon from '../../../../elements/icons';
import { Columns, CommonField } from '../types';
import MuiButton from '../../../../mui/MuiButton';
import { MuiModalProps } from '../../../../mui/MuiModal/MuiModal';
import styles from './useInquiriesFormField.module.scss';

export const useInquiriesFormField = ( {
	column,
	onUpdate,
	onReset,
	onBlur,
	label: propsLabel,
	services: propsServices,
	required: propsRequired,
	multiple: propsMultiple,
	...props
}: CommonField & {
	onUpdate: ( props: {
		label: string;
		services?: string[];
		required: boolean;
		multiple?: boolean | null;
	} ) => void;
	onReset?: () => void;
	modalButton?: React.ReactNode;
	onBlur?: () => void;
} ) => {
	const [ open, setOpen ] = useState( false );
	const [ label, setLabel ] = useState( propsLabel );
	const [ services, setServices ] = useState<string[]>( propsServices );
	const [ required, setRequired ] = useState( propsRequired );
	const [ multiple, setMultiple ] = useState( propsMultiple );

	const reset = useCallback( () => {
		setLabel( propsLabel );
		setServices( propsServices );
		setMultiple( propsMultiple );
		setRequired( props.forceRequired || propsRequired || false );
		onReset?.();
	}, [
		propsLabel,
		propsServices,
		propsMultiple,
		props.forceRequired,
		propsRequired,
		onReset,
	] );

	// set local values from parent state when opening the modal
	useEffect( () => {
		if ( open ) {
			reset();
		}
	}, [ open, reset ] );

	const containerProps: Pick<ListItemProps, 'className' | 'secondaryAction'> = {
		className: classNames(
			styles.container,
			column === Columns.Both
				? styles.both
				: column === Columns.Left
					? styles.left
					: styles.right
		),
		secondaryAction: (
			<IconButton onClick={ () => setOpen( true ) }>
				<Icon type='pencil' className={ styles.icon } />
			</IconButton>
		),
	};

	const DragHandle: React.FC = ( props ) => (
		<ListItemButton className={ styles.dragHandleContainer } { ...props }>
			<Icon type='drag' className={ styles.icon } data-cy='drag-handle' />
		</ListItemButton>
	);

	const modalProps: Pick<MuiModalProps, 'title' | 'controls'> & {
		muiModalProps: Pick<
		MuiModalProps['muiModalProps'],
		'open' | 'onClose' | 'PaperProps'
		>;
	} = {
		title: props.description,
		muiModalProps: {
			open,
			onClose: () => {
				setOpen( false );
				reset();
			},
			// Stupid casting because of course { arbitrary: string } is a prop on a div...
			PaperProps: { [ 'data-cy' ]: 'field-configuration-modal' } as Partial<
			PaperProps<'div', any>
			>,
		},
		controls: (
			<MuiButton
				data-cy='field-configuration-done'
				onClick={ () => {
					setOpen( false );
					onUpdate( { label, services, multiple, required } );
					onBlur?.();
				} }
			>
				Done
			</MuiButton>
		),
	};

	return {
		open,
		setOpen,
		containerProps,
		DragHandle,
		label,
		setLabel,
		services,
		setServices,
		required,
		setRequired,
		multiple,
		setMultiple,
		modalProps,
		classNames: {
			modal: styles.modal,
			buttons: styles.buttons,
			addButton: classNames( styles.icon, styles.addButton ),
			text: styles.text,
		},
	};
};
